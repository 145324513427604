.manage-trail-card {
    width: 25%;
    padding: 20px;
    padding-bottom: 40px;
    height: 275px;
    cursor: pointer;
    margin: 10px;
}

.manage-trail-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    /* border: 2px solid red; */
}

.manage-trail-card-img {
    width: 100%;
    height: 200px;
    border-radius: 15px;
    object-fit: cover;
}

.manage-trail-card-img-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-direction: wrap;
}

.manage-trail-name {
    font-weight: bolder;
    padding-bottom: 4px;
    padding-top: 5px;
}

.manage-park-name {
    text-decoration: underline;
    padding-bottom: 4px;
}

.manage-park-stats {
    padding-bottom: 4px;
}

/* #manage-cards-top {
    background-color: rgb(232, 231, 231);
} */

#manage-cards-bottom {
    /* background-color: beige; */
    padding-bottom: 30px;
}

.banner-img-container {
    height: 400px;
    text-align: center;
    justify-content: center;
}

.banner-img {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 400px;
}

.banner-text {
    position: absolute;
    top: 210px;
    left: 38%;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 48px;
    font-weight: lighter;
}


.section-headers-top {
    text-align: center;
    /* background-color: rgb(232, 231, 231); */
    margin: 0;
    padding-top: 30px;
}

.section-headers-bottom {
    text-align: center;
    /* background-color: beige; */
    margin: 0;
    padding-top: 30px;
    padding-bottom: 20px;
}

.user-stats {
    background-color: darkgreen;
    height: 60px;
    color: white;
    font-size: 28px;
    text-align: center;
    padding-top: 25px;
}

.collection-card-top {
    padding-bottom: 5px;
}

.my-trails-sections-container {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.my-trails-section {
    /* border: 2px solid purple; */
    border-radius: 20px;
    /* padding-bottom: 20px; */
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    background-color: rgb(232, 231, 231);
}

.my-favorites-section {
    /* border: 2px solid purple; */
    background-color: beige;
    border-radius: 20px;
    margin-bottom: 20px;
}

.my-collections-section {
    background-color: rgb(232, 231, 231);
    border-radius: 20px;
    margin-bottom: 20px;
}
