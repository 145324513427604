.footer-container {
    height: 220px;
    background-color: #32690f;
    display: flex;
    flex-direction: row;
}

.footer-headers {
    color: white;
    font-size: 28px;
}

.footer-left {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-left: 50px;
}

.footer-right {
    width: 66%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.name {
    color: white;
    font-size: 18px;
}

.fa-brands {
    color: white;
    font-size: 36px;

}

.link-container-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.link-container-right {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
    padding-top: 55px;
}
