.header-dropdown-button {
  align-items: center;
  border: none;
  background: none;
  display: flex;
  height: 35px;
  font-size: 28px;
  justify-content: center;
  padding: 10px;
  top: 20px;
  width: 80px;
}

.header-dropdown-button:hover {
  cursor: pointer;
  color: darkgreen;
}

.hidden {
  display: none;
}

.profile-dropdown {
  position: absolute;
}

.navbar-leftmost {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 25px;
  width: 623px;
}

.navbarlink:hover {
  color: darkgreen;
}

.navbar-rightmost {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  height: 30px;
  margin-top: 5px;
  padding: 0;
  margin-top: 20px;
}

.profile-dropdown {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  list-style-type: none;
  padding: 10px 20px;
  position: absolute;
  top: 74px;
  right: 12px;
  background-color: white;
  min-width: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.profile-dropdown.hidden {
  display: none;
}

.navbar-leftmost>li {
  margin-right: 30px;
}

li>* {
  text-decoration: none;
  color: black;
}

.just-login {
  font-size: 20px;
}

.navbar-links-left:hover {
  color: darkgreen;
}

.main-nav-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logo {

  height: 35px;
  width: 180px;
}

.modal-text {
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.category-link:hover {
  cursor: pointer;
  color: darkgreen;
}
