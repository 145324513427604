.cat-banner-container {
    height: 400px;
    text-align: center;
    justify-content: center;
}

.cat-banner-text {
    position: absolute;
    top: 200px;
    left: 44%;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 56px;
    font-weight: lighter;
}

.category-trail-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 30px;
}

.trail-card-img {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 400px;
}
