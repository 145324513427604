.collection-modal-parent-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.add-to-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
