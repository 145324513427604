.signupForm>h2 {
  margin: 0;
  padding: 0;
}

.signupForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem;
  gap: 4px;
  width: 20rem;
  height: auto;
}

.signupForm>* {
  display: flex;
  flex-direction: column;
}

.signup-button-container {
  display: flex;
  align-items: center;
}

.signup-button {
  width: 100%;
  height: 2rem;
  border: none;
  width: 50%;
  text-align: center;
  background: darkgreen;
  color: white;
  padding: 10px;
  margin: 10px;
}

.errors {
  color: #FF5A5F;
}

.signupForm>label>input {
  border: 2px solid black;
  padding: 4px 4px;
}
