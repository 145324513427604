.test {
  position: absolute;
  font-size: 68px;
  color: white;
  z-index: 1;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}


.carousel {
  height: 400px;
}

.car-img {
  height: 400px;
  object-fit: cover;
}

.slider-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
