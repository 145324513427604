.average-rating-number {
    font-size: 42px;
    color: #32690f;
}


.average-rating-number-banner {
    font-size: 32px;
    font-weight: bold;
}

.average-rating-text {
    font-size: 36px;
    color: gold;
}

#banner-rating-icon {
    color: gold;
    font-size: 32px;
    outline: white;
}

.cat-trail-card {
    cursor: pointer;
}

.collection-button-container {
    border: 1px solid black;
    height: 80px;
    border-radius: 10px;
    background-color: rgb(244, 241, 241);
    align-items: center;
    justify-content: center;
    display: flex;
    width: 40%;
    /* border: 2px solid red; */
}

.cover-photo-container {
    height: 400px;
    justify-content: center;
    background-color: beige;
}


.create-review-button-container {
    display: flex;
}

/* .create-review-button {
    width: 20px;
    height: 50px;
    border: none;
    width: 100%;
    text-align: center;
    background: #32690f;
    color: white;
    padding: 10px;
    margin-bottom: 70px;
    cursor: pointer;
} */

.detail-page-container {
    width: 97%;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    padding-top: 20px;
    /* border: 2px solid purple; */
    /* align-items: center; */
    justify-content: space-evenly;
}

.detail-stats-res {
    font-size: 22px;
    font-weight: bolder;
    color: darkgreen;
}

.detail-page-description {
    grid-column-start: 1;
    grid-column-end: 5;
    font-size: 20px;
    margin: 40px;
}

.detail-banner-text {
    position: absolute;
    top: 270px;
    left: 1%;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: lighter;
}

.detail-stats-container {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    padding-top: 10px;
    background-color: beige;

}

.detail-stats {
    width: 25%;
    height: 50px;
    text-align: center;
    border-bottom: 1px solid gray;
}

.fa-bookmark {
    color: gold;
    font-size: 60px;
}

#on {
    color: green;
    font-size: 60px;
}

.favorite-button {
    border: none;
    background: none;
    cursor: pointer;

}

.favorite-button-container {
    position: absolute;
    top: 150px;
    left: 92%;
}

.individual-review {
    font-size: 18px;
    background-color: rgb(244, 241, 241);
}

.individual-review-star {
    color: gold;
    font-size: 20px;
}

.individual-review-username {
    font-size: larger;
}

.open-collection-modal-button {
    height: 50px;
    width: 150px;
    border-radius: 20px;
    background: darkgreen;
    color: white;
    cursor: pointer;
}

.open-review-modal-button {
    /* margin-left: 75px; */
    height: 50px;
    width: 150px;
    border-radius: 20px;
    background: darkgreen;
    color: white;
    cursor: pointer;
}

.park-name-h2 {
    text-decoration: underline;
}

.parent-container {
    background-color: rgb(244, 241, 241);
}

.review-area {
    margin-bottom: 10px;
    margin-left: -30px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
    background-color: rgb(244, 241, 241);
    width: 100%;
    /* border: 2px solid red; */
}

.reviews-ul {
    list-style: none;
    width: 100%;
    /* border: 2px solid orange; */
}

.reviews-ul-container {
    list-style: none;
    width: 60%;
    /* border: 2px solid blue; */
    display: flex;
    justify-content: center;
}

.review-totals-container {
    margin-bottom: 5px;
    margin-left: 10px;
    border: 1px solid gray;
    border-radius: 10px;
    padding-left: 10px;
    background-color: rgb(244, 241, 241);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* border: 2px solid blue; */
    height: 80px;
    width: 40%;
}



.related-trails-container {
    margin-left: 20px;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    width: 35%;
    align-items: center;
    background-color: beige;
    border-radius: 20px;
}

.related-trails-cards {
    padding-bottom: 20px;
    /* padding-left: 40px; */
    width: 80%;
}

.reviews-and-related {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* border: 4px solid red; */
    width: 100%;
}

.trail-card-img {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 400px;
    filter: brightness(80%);
}


.trail-detail-subheader-container {
    height: 50px;
    background-color: rgb(244, 241, 241);

}

.trail-detail-subheader-text {
    margin-left: 30px;
    padding-top: 15px;
}

#yes {
    color: green;
}
