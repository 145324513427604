* {
    font-family: Arial, Helvetica, sans-serif;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input:focus {
    outline: none;
}

.all-trails-h1 {
    text-align: center;
    padding-left: 40px;
    /* padding-top: 20px; */
    /* border: 2px solid purple; */
    border-radius: 40px;
    width: 80%;
    height: 70px;
    background-color: #32690f;
    display: flex;
    justify-content: center;
    align-items: center;
}

.all-trails-h1-container {
    /* border: 2px solid red; */
    background-color: white;
    color: aliceblue;
    /* margin-bottom: 20px; */
    /* width: 95%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.collections-h1-container {
    /* border: 2px solid red; */
    background-color: white;
    color: aliceblue;
    margin-bottom: 20px;
    /* width: 95%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.popular-collections-h1 {
    text-align: center;
    padding-left: 40px;
    /* padding-top: 20px; */
    /* border: 2px solid purple; */
    border-radius: 40px;
    width: 80%;
    height: 70px;
    background-color: #32690f;
    display: flex;
    justify-content: center;
    align-items: center;
}

.browse-by-activity {
    margin-left: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.categories-container {
    background-color: beige;
    height: 400px;
    padding-top: 10px;
}

.cat-pic-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.cat-pics-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    color: black;
}

.cat-pics-navlink {
    text-decoration: none;
}

.cat-pics:hover {
    transform: scale(1.05)
}

.cat-pics {
    border-radius: 100%;
}

.trail-card {
    width: 25%;
    padding: 10px;
    padding-bottom: 40px;
    height: 250px;
    cursor: pointer;
    margin: 5px;
    filter: brightness(90%);
}

.trail-card:hover {
    transform: scale(1.03)
}

.trail-cards-container-container {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.trail-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 95%;
    background-color: beige;
    border-radius: 20px;
}

.landing-trail-card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 16px;
}

.landing-collection-container {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    /* justify-content: center;
    align-items: center;
    width: 90%; */
}

.trail-card-img-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-direction: wrap;

}

.trail-name {
    font-weight: bolder;
    padding-bottom: 4px;
    padding-top: 5px;
}

.park-name {
    text-decoration: underline;
}

.search-bar-container {
    /* border: 1px solid lightgray; */
    /* border: 2px solid red; */
    padding: 8px;
    display: flex;
    flex-direction: row;
    background-color: beige;
    justify-content: center;
    position: relative;
}

.search-bar {
    border: none;
    width: 650px;
    height: 80px;
    border-radius: 40px;
    bottom: 45px;
    text-align: center;
    font-size: 25px;
    overflow: visible;
    position: relative;
    /* display: inline-block; */
    padding-right: 35px;
    margin-left: 40px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.17);
}



.search-button {
    border: 4px solid green;
    border-radius: 100%;
    background-color: #32690f;
    height: 58px;
    font-size: 40px;
    color: aliceblue;
    position: relative;
    bottom: 35px;
    right: 78px;
    margin-left: 10px;
    /* display: inline-block; */
}

.search-button:hover {
    cursor: pointer;
    color: yellowgreen;
}
