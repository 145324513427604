.update-trail-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem;
  gap: 4px;
  width: 25rem;
  height: auto;
  margin: auto;
}

.update-trail-form>h2 {
  margin: 0;
  padding: 0;
}

.update-trail-form-parent {
  background-color: beige;
  border-top: 2px solid #32690f;
}

.update-trail-form>* {
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
}

.update-trail-form-button-container {
  display: flex;
  align-items: center;
}

.update-trail-form-button {
  width: 100%;
  height: 2rem;
  border: none;
  width: 100%;
  text-align: center;
  background: #32690f;
  color: white;
  padding: 10px;
  margin-bottom: 70px;
  cursor: pointer;
}

.errors {
  color: #FF5A5F;
}

.update-trail-form>label {
  font-size: 16px;
}

.update-trail-form>label>input {
  border: 2px solid black;
  padding: 4px 4px;
}

.update-trail-form>label>select {
  border: 2px solid black;
  padding: 4px 4px;
}

.update-trail-form>label>textarea {
  border: 2px solid black;
  padding: 4px 4px;
  font-family: Arial, Helvetica, sans-serif;
}
