.delete-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.delete-modal-contents {
    margin: 40px;
    align-items: center;
    justify-content: center;
}

.delete-yes-button {
    height: 30px;
    width: 60px;
    background-color: darkgreen;
    color: white;
    border: none;
    border-radius: 5px;
}

.delete-cancel-button {
    height: 30px;
    width: 60px;
    background-color: darkgreen;
    color: white;
    border: none;
    border-radius: 5px;
}

.delete-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.delete-modal-header {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
}
