.collections-banner-img-container {
    height: 400px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.collections-banner-img {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 400px;
}

.collections-banner-text {
    position: absolute;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 48px;
    font-weight: lighter;
}

.collection-page-trail-card {
    width: 25%;
    padding: 10px;
    padding-bottom: 40px;
    height: 120px;
    cursor: pointer;
    margin: 5px;
}

.collection-page-trail-card:hover {
    transform: scale(1.03);
}

.trail-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 30px;
}

.collections-trail-card-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    filter: brightness(80%);
}

.collection-card-img-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-direction: wrap;
    height: 150px;
    text-align: center;
    position: relative;
}

.collection-card-trail-name {
    position: absolute;
    top: 40%;
    color: aliceblue;
    font-weight: bolder;
    width: 100%;
    font-size: 20px;
}
