.off {
  color: #ccc;
}

.on {
  color: gold;
}

.review-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.review-form-textarea {
  border: 2px solid black;
  padding: 4px 4px;
  font-family: Arial, Helvetica, sans-serif;
}

.review-submit-button {
  height: 40px;
  width: 100px;
  color: white;
  background-color: darkgreen;
  border-radius: 15px;
  margin-top: 20px;
}

.review-form-header {
  margin-bottom: 20px;
  font-size: 24px;
}

.star {
  justify-content: center;
  font-size: 24px;
}

.star-rating {
  text-align: center;
  line-height: 20px;
}

.stars-word {
  font-size: 14px;
}

#star-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
